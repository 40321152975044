import React from "react";

const HomePage = React.lazy(() =>
  import("containers/pages/homePage/homePage")
);

const PrintPage = React.lazy(() =>
  import("components/pages/printPage/printPage")
);

const NotFoundPage = React.lazy(() =>
  import("components/pages/notFoundPage/notFoundPage")
);

const routes = [
  {
    path: "/order",
    name: "Orden de pago",
    icon: null,
    component: HomePage,
    loggedIn: false,
    public: true
  },
  // {
  //   path: "/print",
  //   name: "Imprimir comprobante",
  //   icon: null,
  //   component: PrintPage,
  //   loggedIn: false,
  //   public: true
  // },
  {
    path: "/notfound",
    name: "Pagina no encontrada",
    icon: null,
    component: NotFoundPage,
    loggedIn: false,
    public: true
  }
];

export default routes;
