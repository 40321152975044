import { 
    showToast,
    hideToast,
  } from "./actions";

  import { handleActions } from "redux-actions";

  const initialState = {
    toastType:null,
    toastMessage:"",
    showingToast:false
  };

  const toast = handleActions(
    new Map([
      [
        showToast,
        (state, action) => ({
          ...state,
          toastType: action.payload.toastType,
          toastMessage: action.payload.toastMessage,
          showingToast: true
        })
      ],
      [
        hideToast,
        (state, action) => ({
          initialState
        })
      ]
    ]),
    initialState
  );
  
  export default toast;