import { get } from 'lodash-es';
import { createActions } from "redux-actions";
import { getAuthData, getUserData, setAuthData, setUserData } from 'utils/auth';
import fakeOrderJson from 'fakedata/fakeOrder';
import fakeOrderCompletedJson from 'fakedata/fakeOrderCompleted';
import apiConstants from "constants/apiConstants.js";

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "ORDER", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

export const { 
  // saveOrder,
  clearOrder,
  fetchOrderStart,
  fetchOrderSuccess,
  fetchOrderFail,
  toggleDetails,
  toggleDiscountInput,
  setFakeOrder,
  applyDiscount,
  updateCompletedOrder
} = createActions(
  {
    // SAVE_ORDER: (token, expiresIn) => ({
    //   token,
    //   expiresIn
    // }),
    CLEAR_ORDER: () => undefined,
    FETCH_ORDER_START: () => undefined,
    FETCH_ORDER_SUCCESS: (data) => ({
      description: data.description,
      paymentMethod: data.payment_method,
      currency: data.currency,
      totalAmount: data.amount,
      paymentState: data.payment_state,
      shop: data.shop,
      operationId: data.operation_id,
      paymentDetails: data.payment_details,
      paymentDetailsItems: data.payment_details_items,
      successUrl: data.success_url,
      failureUrl: data.failure_url,
      expirationDate: data.expiration_date,
      creationDate: data.creation_date,
      token: data.token,
      clientDescription: data.client_description,
      clientId: data.client_id,
      updateDate: data.update_date,
      paymentMethodDescription: data.payment_method_description,
      transactionId: data.transaction_id,
      logoUrl: data.logo_url
    }),
    FETCH_ORDER_FAIL: (error) => ({
      error
    }),
    TOGGLE_DETAILS: () => undefined,
    TOGGLE_DISCOUNT_INPUT: () => undefined,
    SET_FAKE_ORDER: () => undefined,
    UPDATE_COMPLETED_ORDER: (updateDate, paymentMethodDescription, transactionId) => ({
      updateDate,
      paymentMethodDescription,
      transactionId
    }),
    APPLY_DISCOUNT: (paymentDiscountDetailsItems) => ({
      paymentDiscountDetailsItems
    })
  },
  actionOptions
);

// -------------------- //
// ACTIONS ASINCRÓNICOS //
// -------------------- //

// Dispatch es la funcion dispatcher que ejecutará la acción generada
// getState es un método de React-Redux, devuelve el estado del store antes del dispatch
// api es un parametro extra enviado desde el middleware del store, usando thunk.withExtraArgument()
export function fetchOrder(token) {
    return async (dispatch, getState, api) => {
        await dispatch(fetchOrderStart());
        // Fake login
        if (apiConstants.enableFakeData && token === "123456"){
          await dispatch(fakeFetchOrder());
          return;
        }
        if (apiConstants.enableFakeData && token === "654321"){
          await dispatch(fakeFetchOrderCompleted());
          return;
        }

        api.payment.getOrder(token).then(async (response) => {
          dispatch(fetchOrderSuccess(response.data.data));
        }).catch((error) => {
            console.log("Error:",error);
            const message = get(
                error,
                'response.data.error_description',
                'Ha ocurrido un error al cargar los datos del a orden.',
            );
            dispatch(fetchOrderFail(message));
        });
    };
}

export function fakeFetchOrder() {
  return async (dispatch, getState, api) => {
      await dispatch(fetchOrderStart());
      const data = fakeOrderJson;
      dispatch(fetchOrderSuccess(data));
      dispatch(setFakeOrder());
      return;
  };
}

export function fakeFetchOrderCompleted() {
  return async (dispatch, getState, api) => {
      await dispatch(fetchOrderStart());
      const data = fakeOrderCompletedJson;
      dispatch(fetchOrderSuccess(data));
      dispatch(setFakeOrder());
      return;
  };
}