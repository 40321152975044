import routeList from "./routeList";
import React, { Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import FullScreenLoader from "components/common/fullscreenLoader/fullscreenLoader";
// import SuspenseFallback from "components/common/suspenseFallback/suspenseFallback";

// const SuspenseFallback = () => <div></div>;

export const AppRoutes = props => {
  let routes = (
    <Suspense fallback={<FullScreenLoader />}>
      <Switch>
        {routeList.map((route, key) => {
          if (route.loggedIn === props.isAuthenticated || route.public){
            return (
              <Route exact path={route.path} component={route.component} key={key} />
            );
          }
          return null;
        })}
        <Route render={() => <Redirect to="/notfound" />} />
      </Switch>
    </Suspense>
  )

  return routes
}

export default AppRoutes;
