import { 
  // saveOrder,
  clearOrder,
  fetchOrderStart,
  fetchOrderSuccess,
  fetchOrderFail,
  toggleDetails,
  toggleDiscountInput,
  setFakeOrder,
  applyDiscount,
  updateCompletedOrder
} from "./actions";

import { handleActions } from "redux-actions";

const orderInitialState = {
  description: "",
  paymentMethod: "",
  currency: "",
  totalAmount: null,
  paymentState: "",
  shop: "",
  operationId: "",
  paymentDetails: "",
  paymentDetailsItems: [],
  paymentDiscountDetailsItems: [],
  successUrl: "",
  failureUrl: "",
  expirationDate: "",
  creationDate: "",
  token: "",
  clientDescription: "",
  updateDate: "",
  paymentMethodDescription: "",
  transactionId: null,
  logoUrl: ""
};

const initialState = {
  order: null,
  paymentDiscountDetailsItems:[],
  fetchError: null,
  fetching: false,
  detailsIsOpen: true,
  discountInputIsOpen: true,
  isFakeOrder: false
};

const order = handleActions(
  new Map([
    // [
    //   saveOrder,
    //   (state, action) => ({
    //     ...state,
    //     description: action.payload.description,
    //     paymentMethodId: action.payload.paymentMethodId,
    //     currencyId: action.payload.currencyId,
    //     customerIp: action.payload.customerIp,
    //     totalAmount: action.payload.totalAmount,
    //     paymentStateId: action.payload.paymentStateId,
    //     shopId: action.payload.shopId,
    //     operationId: action.payload.operationId,
    //     paymentDetails: action.payload.paymentDetails,
    //     successUrl: action.payload.successUrl,
    //     failureUrl: action.payload.failureUrl
    //   })
    // ],
    [
      clearOrder,
      (state, action) => ({
        ...initialState
      })
    ],
    [
      fetchOrderStart,
      (state, action) => ({
        ...state,
        order: null,
        fetching: true,
        fetchError: null
      })
    ],
    [
      fetchOrderSuccess,
      (state, action) => ({
        ...state,
        order: {
          description: action.payload.description,
          paymentMethod: action.payload.paymentMethod,
          currency: action.payload.currency,
          totalAmount: action.payload.totalAmount,
          paymentState: action.payload.paymentState,
          shop: action.payload.shop,
          operationId: action.payload.operationId,
          paymentDetails: action.payload.paymentDetails,
          paymentDetailsItems: action.payload.paymentDetailsItems,
          successUrl: action.payload.successUrl,
          failureUrl: action.payload.failureUrl,
          expirationDate: action.payload.expirationDate,
          creationDate: action.payload.creationDate,
          token: action.payload.token,
          clientDescription: action.payload.clientDescription,
          clientId: action.payload.clientId,
          updateDate: action.payload.updateDate,
          paymentMethodDescription: action.payload.paymentMethodDescription,
          transactionId: action.payload.transactionId,
          logoUrl: action.payload.logoUrl
        },
        fetching: false
      })
    ],
    [
      fetchOrderFail,
      (state, action) => ({
        ...state,
        fetching: false,
        fetchError: action.payload.error
      })
    ],
    [
      toggleDetails,
      (state, action) => ({
        ...state,
        detailsIsOpen: !state.detailsIsOpen
      })
    ],
    [
      toggleDiscountInput,
      (state, action) => ({
        ...state,
        discountInputIsOpen: !state.discountInputIsOpen
      })
    ],
    [
      setFakeOrder,
      (state, action) => ({
        ...state,
        isFakeOrder: true
      })
    ],
    [
      updateCompletedOrder,
      (state, action) => ({
        ...state,
        order: {
          ...state.order,
          updateDate: action.payload.updateDate,
          paymentMethodDescription: action.payload.paymentMethodDescription,
          transactionId: action.payload.transactionId
        }
      })
    ],
    [
      applyDiscount,
      (state, action) => ({
        ...state,
        paymentDiscountDetailsItems: action.payload.paymentDiscountDetailsItems
      })
    ],
  ]),
  initialState
);

export default order;