import { 
  clearNavigation,
  goToStart,
  goToLogin,
  goToDetails,
  goToPaymentCompleted,
  goToError,
  showDetailsGuestScreen,
  showDetailsPaymentMethodScreen,
  showDetailsCombinedPaymentScreen,
  showDetailsOverviewScreen,
  showWalletTab,
  showCardTab,
  showMiiiiersTab,
  showQRTab,
  showPrintScreen,
  goToCombinedPageTwo,
  clearCardTabSubscreen,
  goToCardSubscreenNewCard,
  goToCardSubscreenInstallments,
  goToNextCombinedPage
} from "./actions";

import { handleActions } from "redux-actions";
import { PaymentMethodTab, PaymentPage, PaymentDetailsScreen, CombinedPaymentSteps, CreditCardTabSubscreen } from "constants/paymentConstants";

const initialState = {
  currentPage: 0,
  currentPaymentDetailsScreen: 0,
  currentPaymentMethodTab: 0,
  printScreenVisible: false,
  // combinedPaymentStep: CombinedPaymentSteps.STEP_ONE,
  combinedPaymentStep: 0,
  cardTabSubscreen: CreditCardTabSubscreen.NONE
};

const navigation = handleActions(
  new Map([
    [
      clearNavigation,
      (state, action) => ({
        ...initialState
      })
    ],
    [
      goToStart,
      (state, action) => ({
        ...state,
        currentPage: PaymentPage.START
      })
    ],
    [
      goToLogin,
      (state, action) => ({
        ...state,
        currentPage: PaymentPage.LOGIN
      })
    ],
    [
      goToDetails,
      (state, action) => ({
        ...state,
        currentPage: PaymentPage.PAYMENT_DETAILS
      })
    ],
    [
      goToPaymentCompleted,
      (state, action) => ({
        ...state,
        currentPage: PaymentPage.PAYMENT_COMPLETED
      })
    ],
    [
      goToError,
      (state, action) => ({
        ...state,
        currentPage: PaymentPage.ERROR
      })
    ],
    [
      showDetailsGuestScreen,
      (state, action) => ({
        ...state,
        currentPaymentDetailsScreen: PaymentDetailsScreen.PAYMENT_GUEST
      })
    ],
    [
      showDetailsPaymentMethodScreen,
      (state, action) => ({
        ...state,
        currentPaymentDetailsScreen: PaymentDetailsScreen.PAYMENT_METHOD,
        cardTabSubscreen: CreditCardTabSubscreen.NONE
      })
    ],
    [
      showDetailsCombinedPaymentScreen,
      (state, action) => ({
        ...state,
        currentPaymentDetailsScreen: PaymentDetailsScreen.PAYMENT_COMBINED,
        combinedPaymentStep: CombinedPaymentSteps.STEP_ONE,
        cardTabSubscreen: CreditCardTabSubscreen.NONE
      })
    ],
    [
      showDetailsOverviewScreen,
      (state, action) => ({
        ...state,
        currentPaymentDetailsScreen: PaymentDetailsScreen.PAYMENT_OVERVIEW
      })
    ],
    [
      showWalletTab,
      (state, action) => ({
        ...state,
        currentPaymentMethodTab: PaymentMethodTab.WALLET
      })
    ],
    [
      showCardTab,
      (state, action) => ({
        ...state,
        currentPaymentMethodTab: PaymentMethodTab.CARD,
        cardTabSubscreen: CreditCardTabSubscreen.NONE
      })
    ],
    [
      showMiiiiersTab,
      (state, action) => ({
        ...state,
        currentPaymentMethodTab: PaymentMethodTab.MIIIIERS
      })
    ],
    [
      showQRTab,
      (state, action) => ({
        ...state,
        currentPaymentMethodTab: PaymentMethodTab.QR
      })
    ],
    [
      showPrintScreen,
      (state, action) => ({
        ...state,
        printScreenVisible: true
      })
    ],
    [
      goToNextCombinedPage,
      (state, action) => ({
        ...state,
        combinedPaymentStep: action.payload.combinedPaymentStep
      })
    ],
    [
      goToCombinedPageTwo,
      (state, action) => ({
        ...state,
        combinedPaymentStep: CombinedPaymentSteps.STEP_TWO
      })
    ],
    [
      clearCardTabSubscreen,
      (state, action) => ({
        ...state,
        cardTabSubscreen: CreditCardTabSubscreen.NONE
      })
    ],
    [
      goToCardSubscreenNewCard,
      (state, action) => ({
        ...state,
        cardTabSubscreen: CreditCardTabSubscreen.ADD_CREDIT_CARD
      })
    ],
    [
      goToCardSubscreenInstallments,
      (state, action) => ({
        ...state,
        cardTabSubscreen: CreditCardTabSubscreen.SELECT_INSTALLMENTS
      })
    ]
  ]),
  initialState
);

export default navigation;