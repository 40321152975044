import { createSelector } from "reselect";

const mapQRs = createSelector(
  qrList => qrList,
  qrList =>
    typeof qrList !== "undefined" &&
    qrList !== null &&
    qrList.length > 0
      ? qrList.map((element, index) => ({
          cbu: element.cbu,
          holderName: element.cardDetail.holderName,
          holderCuit: element.cardDetail.holderCuit,
          bankName: element.cardDetail.bankName,
          footerLeft: element.cardFooterText.left,
          footerRight: element.cardFooterText.right,
          color: element.cardMiiiiLogoColor,
          img: element.cardImageUrl
      }))
      : []
);

export default mapQRs;
