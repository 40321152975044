import { authenticationAnonInstance, authenticationInstance } from "./api.js";

export default {
  login: (username, password) => authenticationAnonInstance.post('/api/authentication/app/Login', {
    // grant_type: 'password',
    username,
    password
  }),
  // getuser: () => axiosInstance.get(getUrl('/login/getuser'))
};
