import { combineReducers } from "redux";
import modal from "./modal/reducer";
import order from "./order/reducer";
import auth from "./auth/reducer";
import payment from "./payment/reducer";
import navigation from "./navigation/reducer";
import toast from "./toast/reducer";

export default combineReducers({
  modal,
  order,
  auth,
  payment,
  navigation,
  toast
});
