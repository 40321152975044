import {
  setCombinedPaymentAmount,
  updatePaymentMethods,
  resetPaymentMethods,
  clearPayment,
  payWithNewCard,
  payWithExistingCard,
  payWithQr,
  payWithWallet,
  payWithMiiiiers,
  configureAvailablePaymentMethods,
  configureClientPaymentMethods,
  fetchPayStart,
  fetchPaySuccess,
  fetchPayFail,
  fetchPayReset,
  fetchValidDiscountStart,
  fetchValidDiscountSuccess,
  fetchValidDiscountFail,
  payCombined,
  selectInstallmentsOption,
  fetchInstallmentsPlansStart,
  fetchInstallmentsPlansFail,
  fetchInstallmentsPlansSuccess,
  resetInstallmentData
} from "./actions";

import { handleActions } from "redux-actions";
import { PaymentMethod, PaymentPage } from "../../constants/paymentConstants";

const initialNewCreditCard = {
  ownerName: null,
  cardNumber: null,
  cardExpirationDate: null,
  cardCVV: null
};

const initialPaymentResponse = {
  id: null,
  description: null,
  state: null,
  currency: null,
  amountGross: null,
  amountNet: null,
  date: new Date(),
  sourcePayment: {
    paymentType: null,
    sourceUserName: null,
    cardId: null,
    cardNumber: null,
    cardExpirationDateMonth: null,
    cardExpirationDateYear: null,
    cardSecurityCode: null,
    cardHolderName: null,
    saveCard: null
  },
  client: {
    username: null,
    balance: null,
    pictureUrl: null,
    hasCvuRequestPending: null,
    itauAccountActivationUrl: null,
    itauAccountActivationButtonSysParam: null,
    showItauAccountActivation: null,
    showItauDebitSubscription: null,
    forceItauDebitSubscription: null,
    forceItauOnboardingInvitados: null,
    isOwner: null,
    isFamiliar: null,
    isEmployee: null,
    miiiiCvu: null,
    miiiiAlias: null,
    balances: null,
    miiiiiCvuRequest: null,
    transactions: null,
    expenseDetails: null,
    bankAccounts: null,
    externalSuppliers: null,
    cards: []
  },
  sourceTransactionId: null,
  destinationTransactionId: null,

};

const initialState = {
  newCard: null,
  documentNumber: '',
  selectedItemId: null,
  selectedCVV: null,
  selectedInstallmentsOption: null,
  selectedPaymentMethod: null,
  availableCards: [],
  availableQRs: [],
  availableWallet: null,
  availableMiiiiersWallet: null,
  clientpaymentmethods: [],
  paymentMethodsConfigured: false,
  fetchError: null,
  fetching: false,
  // fetchingPaymentMethods: false,
  // fetchPaymentMethodsError: null,
  fetchingPay: false,
  fetchPayError: null,
  fetchPayErrorCode: null,
  paymentResponse: null,
  paymentSuccessful: false,
  discountList: [],
  isFetchingDiscount: false,

  isFetchingInstallmentsPlans: false,
  installmentsPlans: [],
  fetchInstallmentsPlansError: null,
  fetchInstallmentsPlansIsSuccess: false,

  // pago combinado
  combinedPayment: false,
  combinedPaymentAmount: 0,
  selectedPaymentMethods: []

};

const payment = handleActions(
  new Map([
    [
      setCombinedPaymentAmount,
      (state, action) => ({
        ...state,
        combinedPaymentAmount: action.payload.combinedPaymentAmount
      })
    ],
    [
      updatePaymentMethods,
      (state, action) => ({
        ...state,
        selectedPaymentMethods: action.payload.paymentMethods
      })
    ],
    [
      resetPaymentMethods,
      state => ({
        ...state,
        selectedPaymentMethods: []
      })
    ],
    [
      clearPayment,
      (state, action) => ({
        ...initialState
      })
    ],
    [
      payWithNewCard,
      (state, action) => ({
        ...state,
        selectedPaymentMethod: action.payload.isCreditCard ? PaymentMethod.CREDIT_CARD : PaymentMethod.DEBIT_CARD,
        newCard: action.payload.newCard,
        selectedItemId: null,
        selectedCVV: null,
        documentNumber: action.payload.documentNumber
      })
    ],
    [
      payWithExistingCard,
      (state, action) => ({
        ...state,
        selectedPaymentMethod: action.payload.isCreditCard ? PaymentMethod.CREDIT_CARD : PaymentMethod.DEBIT_CARD,
        newCard: null,
        selectedItemId: action.payload.cardId,
        selectedCVV: action.payload.cardCVV,
        documentNumber: action.payload.documentNumber
      })
    ],
    [
      payWithQr,
      (state, action) => ({
        ...state,
        selectedPaymentMethod: PaymentMethod.QR,
        newCard: null,
        selectedItemId: action.payload.qrId,
        selectedCVV: null
      })
    ],
    [
      payWithWallet,
      (state, action) => ({
        ...state,
        selectedPaymentMethod: PaymentMethod.WALLET,
        newCard: null,
        selectedItemId: null,
        selectedCVV: null
      })
    ],
    [
      payWithMiiiiers,
      (state, action) => ({
        ...state,
        selectedPaymentMethod: PaymentMethod.MIIIIERS,
        newCard: null,
        selectedItemId: null,
        selectedCVV: null
      })
    ],
    [
      configureAvailablePaymentMethods,
      (state, action) => ({
        ...state,
        selectedPaymentMethod: null,
        newCard: null,
        selectedItemId: null,
        selectedCVV: null,
        availableCards: action.payload.availableCards,
        availableQRs: action.payload.availableQRs,
        availableWallet: action.payload.availableWallet,
        availableMiiiiersWallet: action.payload.availableMiiiiersWallet
      })
    ],
    [
      configureClientPaymentMethods,
      (state, action) => ({
        ...state,
        clientpaymentmethods: action.payload.paymentMethods,
      })
    ],
    [
      fetchPayStart,
      (state, action) => ({
        ...state,
        fetchingPay: true,
        fetchPayError: null,
        fetchPayErrorCode: null,
        paymentResponse: null,
        paymentSuccessful: false
      })
    ],
    [
      fetchPaySuccess,
      (state, action) => ({
        ...state,
        fetchingPay: false,
        paymentResponse: action.payload.data,
        fetchPayErrorCode: null,
        paymentSuccessful: true
      })
    ],
    [
      fetchPayFail,
      (state, action) => ({
        ...state,
        fetchingPay: false,
        fetchPayError: action.payload.message,
        fetchPayErrorCode: action.payload.payment_error_code,
        paymentResponse: null,
        paymentSuccessful: false
      })
    ],
    [
      fetchPayReset,
      (state, action) => ({
        ...state,
        fetchingPay: false,
        fetchPayError: null,
        fetchPayErrorCode: null,
        paymentResponse: null,
        paymentSuccessful: false
      })
    ],
    [
      fetchValidDiscountStart,
      (state, action) => ({
        ...state,
        isFetchingDiscount: true,
        fetchError: null
      })
    ],
    [
      fetchValidDiscountSuccess,
      (state, action) => ({
        ...state,
        fetchError: "",
        discountList: action.payload.data,
        isFetchingDiscount: false
      })
    ],
    [
      fetchValidDiscountFail,
      (state, action) => ({
        ...state,
        fetchError: action.payload.message,
        isFetchingDiscount: false
      })
    ],
    [
      payCombined,
      (state, action) => ({
        ...state,
        combinedPayment: action.payload.enable,
        selectedPaymentMethod: null,
        newCard: null,
        selectedItemId: null,
        selectedCVV: null,
      })
    ],
    [
      selectInstallmentsOption,
      (state, action) => ({
        ...state,
        selectedInstallmentsOption: action.payload.option
      })
    ],
    [
      fetchInstallmentsPlansStart,
      (state, action) => ({
        ...state,
        isFetchingInstallmentsPlans: true,
        fetchInstallmentsPlansError: null,
        fetchInstallmentsPlansIsSuccess: false
      })
    ],
    [
      fetchInstallmentsPlansSuccess,
      (state, action) => ({
        ...state,
        isFetchingInstallmentsPlans: false,
        installmentsPlans: action.payload.data,
        fetchInstallmentsPlansError: null,
        fetchInstallmentsPlansIsSuccess: true
      })
    ],
    [
      fetchInstallmentsPlansFail,
      (state, action) => ({
        ...state,
        isFetchingInstallmentsPlans: false,
        fetchInstallmentsPlansError: action.payload.message,
        fetchInstallmentsPlansIsSuccess: false
      })
    ],
    [
      resetInstallmentData,
      (state, action) => ({
        ...state,
        installmentsPlans: [],
        isFetchingInstallmentsPlans: false,
        fetchInstallmentsPlansError: null,
        fetchInstallmentsPlansIsSuccess: false
      })
    ]
  ]),
  initialState
);

export default payment;