import { createActions } from "redux-actions";

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "MODAL",
  namespace: "-"
};

export const { showModal, hideModal } = createActions(
  {
    SHOW_MODAL: (modalType, modalProps = {}) => ({
      modalType,
      modalProps
    }),
    HIDE_MODAL: undefined
  },
  actionOptions
);
