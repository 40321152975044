export const getAuthToken = () => {
  return localStorage.getItem("token");
};

export const setAuthToken = (token) => {
  return localStorage.setItem("token", token);
};

export const getAuthData = async () => {
  var authenticated = await localStorage.getItem("authenticated");
  var token = await localStorage.getItem("token");
  var expiresIn = await localStorage.getItem("expiresIn");
  return { authenticated, token, expiresIn }
};

export const setAuthData = async (authenticated, token, expiresIn) => {
  await localStorage.setItem("authenticated", authenticated);
  await localStorage.setItem("token", token);
  await localStorage.setItem("expiresIn", expiresIn);
};

export const getUserData = async () => {
  var name = await localStorage.getItem("name");
  var username = await localStorage.getItem("username");
  var documentNumber = await localStorage.getItem("documentNumber");
  return { name, username, documentNumber }
};

export const setUserData = async (name, username, documentNumber) => {
  await localStorage.setItem("name", name);
  await localStorage.setItem("username", username);
  await localStorage.setItem("documentNumber", documentNumber)
};