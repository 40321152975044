import React from "react";
import styles from "assets/css/common/fullscreenLoader/fullscreenLoaderStyle.module.scss"
import imgLoader from "assets/img/loader/loader.gif"

const FullScreenLoader = (props) => {
  
  return (
    <div className={styles["box"]}>
      <img className={styles["centered"]} src={imgLoader} alt="loading..."/>
    </div>
  );
}

export default FullScreenLoader;