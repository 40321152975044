import { createActions } from "redux-actions";
import { fetchPayReset } from "../payment/actions";

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "NAVIGATION", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

export const { 
  clearNavigation,
  goToStart,
  goToLogin,
  goToDetails,
  goToPaymentCompleted,
  goToError,
  showDetailsGuestScreen,
  showDetailsPaymentMethodScreen,
  showDetailsCombinedPaymentScreen,
  showDetailsOverviewScreen,
  showWalletTab,
  showCardTab,
  showMiiiiersTab,
  showQRTab,
  showPrintScreen,
  goToNextCombinedPage,
  goToCombinedPageTwo,
  clearCardTabSubscreen,
  goToCardSubscreenNewCard,
  goToCardSubscreenInstallments
} = createActions(
  {
    CLEAR_NAVIGATION: () => undefined,
    GO_TO_START: () => undefined,
    GO_TO_LOGIN: () => undefined,
    GO_TO_DETAILS: () => undefined,
    GO_TO_PAYMENT_COMPLETED: () => undefined,
    GO_TO_ERROR: () => undefined,
    SHOW_DETAILS_GUEST_SCREEN: () => undefined,
    SHOW_DETAILS_PAYMENT_METHOD_SCREEN: () => undefined,
    SHOW_DETAILS_COMBINED_PAYMENT_SCREEN: () => undefined,
    SHOW_DETAILS_OVERVIEW_SCREEN: () => undefined,
    SHOW_WALLET_TAB: () => undefined,
    SHOW_CARD_TAB: () => undefined,
    SHOW_MIIIIERS_TAB: () => undefined,
    SHOW_Q_R_TAB: () => undefined,
    SHOW_PRINT_SCREEN: () => undefined,
    GO_TO_NEXT_COMBINED_PAGE: (combinedPaymentStep) => ({combinedPaymentStep: combinedPaymentStep}),
    GO_TO_COMBINED_PAGE_TWO: () => undefined,
    CLEAR_CARD_TAB_SUBSCREEN: () => undefined,
    GO_TO_CARD_SUBSCREEN_NEW_CARD: () => undefined,
    GO_TO_CARD_SUBSCREEN_INSTALLMENTS: () => undefined
  },
  actionOptions
);