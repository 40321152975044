import React from "react";
import imgLoaderPrimary from "assets/img/loader/spinner-white-green.gif"
import imgLoaderSecondary from "assets/img/loader/spinner-green-gray.gif"

const imagePaths = [
  imgLoaderPrimary,
  imgLoaderSecondary
];

const ImagePrecacher = (props) => {
  React.useEffect(() => {
    imagePaths.forEach(element => {
      new Image().src = element.fileName;
    });
  }, []);

  return null;
}

export default ImagePrecacher;