import { createSelector } from "reselect";

const mapCreditCards = createSelector(
  creditCardList => creditCardList,
  creditCardList =>
    typeof creditCardList !== "undefined" &&
    creditCardList !== null &&
    creditCardList.length > 0
      ? creditCardList.map((element, index) => ({
          cardId: element.cardDetail.cardId,
          holderName: element.cardDetail.holderName,
          holderCuit: element.cardDetail.holderCuit,
          cardNumber: element.cardDetail.cardNumber,
          cardExpirationDate: element.cardDetail.cardExpirationDate,
          cardType: element.cardDetail.cardType,
          cardTypeId:element.cardDetail.cardTypeId,
          footerLeft: element.cardFooterText.left,
          footerRight: element.cardFooterText.right,
          color: element.cardMiiiiLogoColor,
          img: element.cardDetail.cardBrandUrl
      }))
      : []
);

export default mapCreditCards;
