import React from 'react';
import { AppRoutes } from "./routes";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux'

import ModalRoot from "containers/common/modalRoot/modalRoot";

import { BrowserRouter as Router } from "react-router-dom";

import { createBrowserHistory } from "history";

import { logOut } from "store/auth/actions"
import ImagePrecacher from 'containers/common/imagePrecacher/imagePrecacher';

const mapStateToProps = (state) => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  logOut
}, dispatch);

const hist = createBrowserHistory();

// eslint-disable-next-line no-unused-vars
const unlisten = hist.listen((location, action) => {});

// eslint-disable-next-line no-unused-vars
function App(props) {
  React.useEffect(() => {
    // props.checkAuthentication();
    props.logOut();

    return () => {
      unlisten();
    };
  });

  const app = (
    <React.Fragment>
      <Router history={hist}>
        <AppRoutes isAuthenticated={props.isAuthenticated} />
      </Router>
      <ModalRoot />
      <ImagePrecacher />
    </React.Fragment>
  );

  return <div className="App">{app}</div>;
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
