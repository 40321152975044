export const PaymentMethod = {
  WALLET: 0,
  DEBIT_CARD: 1,
  CREDIT_CARD: 2,
  MIIIIERS: 3,
  QR: 4
};

export const PaymentPage = {
  START: 0,
  LOGIN: 1,
  PAYMENT_DETAILS: 2,
  PAYMENT_COMPLETED: 3,
  ERROR: 4
};

export const PaymentDetailsScreen = {
  PAYMENT_GUEST: 0,
  PAYMENT_METHOD: 1,
  PAYMENT_OVERVIEW: 2,
  PAYMENT_COMBINED: 3
}

export const PaymentMethodTab = {
  WALLET: 0,
  CARD: 1,
  MIIIIERS: 2,
  QR: 3,
  COMBINED_PAYMENT: 4
}

export const CreditCardTabSubscreen = {
  NONE: 0,
  ADD_CREDIT_CARD: 1,
  SELECT_INSTALLMENTS: 2
}

export const CombinedPaymentSteps = {
  STEP_ONE: 0,
  STEP_TWO: 1
}