import { 
  authenticate,
  unauthenticate,
  saveUser,
  clearUser,
  loginStart,
  loginSuccess,
  loginFail
} from "./actions";

import { handleActions } from "redux-actions";

const initialUserInfo = {
  isFake: false,
  username: null,
  gender: null,
  profile_picture: null,
  neighbourhoodpropertiesregistrationcode: null,
  personid: null,
  name: null,
  family_name: null,
  state: null,
  email: null,
  phone_number: null,
  dni: null,
  birthdate: null,
  isunderage: null
}

const initialState = {
  uuid: null,
  isAuthenticated: null,
  loginError: '',
  token: '',
  expiresIn: 0,
  userInfo: initialUserInfo,
  submitting: false
};

const auth = handleActions(
  new Map([
    [
      authenticate,
      (state, action) => ({
        ...state,
        uuid: "placeholder-uuid",
        isAuthenticated: true,
        token: action.payload.token,
        expiresIn: action.payload.expiresIn
      })
    ],
    [
      unauthenticate,
      (state, action) => ({
        ...state,
        uuid: null,
        isAuthenticated: false,
        token: '',
        expiresIn: 0
      })
    ],
    [
      saveUser,
      (state, action) => ({
        ...state,
        userInfo: {
          isFake: action.payload.isFake ?? false,
          username: action.payload.username,
          gender: action.payload.gender,
          profile_picture: action.payload.profile_picture,
          neighbourhoodpropertiesregistrationcode: action.payload.neighbourhoodpropertiesregistrationcode,
          personid: action.payload.personid,
          name: action.payload.name,
          family_name: action.payload.family_name,
          state: action.payload.state,
          email: action.payload.email,
          phone_number: action.payload.phone_number,
          dni: action.payload.dni,
          birthdate: action.payload.birthdate,
          isunderage: action.payload.isunderage
        }
      })
    ],
    [
      clearUser,
      (state, action) => ({
        ...state,
        userInfo: initialUserInfo
      })
    ],
    [
      loginStart,
      (state, action) => ({
        ...state,
        submitting: true,
        loginError: ''
      })
    ],
    [
      loginSuccess,
      (state, action) => ({
        ...state,
        submitting: false
      })
    ],
    [
      loginFail,
      (state, action) => ({
        ...state,
        submitting: true,
        loginError: action.payload.message
      })
    ]
  ]),
  initialState
);

export default auth;