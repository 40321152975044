import { createSelector } from 'reselect'

let mapInstallmentsPlans = createSelector(  
  installmentsPlans => installmentsPlans,
  installmentsPlans => 
  (installmentsPlans.map(installmentsPlan => ({
    ...installmentsPlan,
  })) )
)
export default mapInstallmentsPlans
