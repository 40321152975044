const CreditCardType = {
  Visa: 0,
  MasterCard: 1,
  AmericanExpress: 2,
  Discover: 3,
  Amex: 4,
  BCGlobal: 5,
  CarteBlanch: 6,
  DinersClub: 7,
  InstaPaymentCard: 8,
  JCBCard: 9,
  KoreanLocal: 10,
  LaserCard: 11,
  Maestro: 12,
  Solo: 13,
  SwitchCard: 14,
  UnionPay: 15,
  NotFormatted: 16,
  Unknown: 17,
  Cabal: 18
}

const defaultCardImg = "https://static.thenounproject.com/png/59173-200.png";

const creditCardInfo = [
  { name: "Visa",
    img: "https://miiiisa-app-prod.s3.us-east-2.amazonaws.com/wallet/CreditCards/Visa.png",
    supported: true },
  { name: "Master Card",
    img: "https://miiiisa-app-prod.s3.us-east-2.amazonaws.com/wallet/CreditCards/MC-Horizontal.png",
    supported: true },
  { name: "American Express",
    img: "https://miiiisa-app-prod.s3.us-east-2.amazonaws.com/wallet/CreditCards/american-express-logo.png",
    supported: false },
  { name: "Discover",
    img: null,
    supported: false },
  { name: "Amex",
    img: null,
    supported: false },
  { name: "BC Global",
    img: null,
    supported: false },
  { name: "Carte Blanch",
    img: null,
    supported: false },
  { name: "Diners Club",
    img: null,
    supported: false },
  { name: "InstaPayment Card",
    img: null,
    supported: false },
  { name: "JCB Card",
    img: null,
    supported: false },
  { name: "Korean Local",
    img: null,
    supported: false },
  { name: "Laser Card",
    img: null,
    supported: false },
  { name: "Maestro",
    img: null,
    supported: false },
  { name: "Solo",
    img: null,
    supported: false },
  { name: "SwitchCard",
    img: null,
    supported: false },
  { name: "UnionPay",
    img: null,
    supported: false },
  { name: "No formateada",
    img: null,
    supported: false },
  { name: "Desconocida",
    img: defaultCardImg,
    supported: false },
  { name: "Cabal",
    img: "https://miiiisa-app-prod.s3.us-east-2.amazonaws.com/wallet/CreditCards/cabal.svg",
    supported: true },
];

export const getCardType = (CC_NUM) => {
  CC_NUM = CC_NUM.replace(/ /g,'');

  var enumResult = RegExp("^((604([2-3][0-9]{2}|400)[0-9]{10}|589657[0-9]{10}))$").test(CC_NUM) ? CreditCardType.Cabal :
                   RegExp("^(4[0-9]{5,})$").test(CC_NUM) ? CreditCardType.Visa :
                   RegExp("^(5[1-5][0-9]{4,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,})$").test(CC_NUM) ? CreditCardType.MasterCard :
                   RegExp("^(3[47][0-9]{4,})$").test(CC_NUM) ? CreditCardType.AmericanExpress :
                   RegExp("^(65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10}))$").test(CC_NUM) ? CreditCardType.Discover :
                   RegExp("^((6541|6556)[0-9]{12})$").test(CC_NUM) ? CreditCardType.BCGlobal :
                   RegExp("^(389[0-9]{11})$").test(CC_NUM) ? CreditCardType.CarteBlanch :
                   RegExp("^(3(?:0[0-5]|[68][0-9])[0-9]{11})$").test(CC_NUM) ? CreditCardType.DinersClub :
                   RegExp("^(63[7-9][0-9]{13})$").test(CC_NUM) ? CreditCardType.InstaPaymentCard :
                   RegExp("^((?:2131|1800|35\\d{3})\\d{11})$").test(CC_NUM) ? CreditCardType.JCBCard :
                   RegExp("^(9[0-9]{15})$").test(CC_NUM) ? CreditCardType.KoreanLocal :
                   RegExp("^((6304|6706|6709|6771)[0-9]{12,15})$").test(CC_NUM) ? CreditCardType.LaserCard :
                   RegExp("^((5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15})$").test(CC_NUM) ? CreditCardType.Maestro :
                   RegExp("^((6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15})$").test(CC_NUM) ? CreditCardType.Solo :
                   RegExp("^((4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13})$").test(CC_NUM) ? CreditCardType.SwitchCard :
                   RegExp("^((62[0-9]{14,17}))$").test(CC_NUM) ? CreditCardType.UnionPay :
                   CreditCardType.Unknown;

  const cardInfo = creditCardInfo[enumResult];
  if (cardInfo.img == null){
    cardInfo.img = defaultCardImg;
  }

  return cardInfo;
}

export const parseCardUrl = (url) => {
  if(url == null || url.toLowerCase() === "unknown"){
    return creditCardInfo[CreditCardType.Unknown];
  }

  for (let index = 0; index < creditCardInfo.length; index++) {
    const element = creditCardInfo[index];
    if (url === element.img)
      return element;
  }
}