import { get } from 'lodash-es';
import { createActions } from "redux-actions";
import { getAuthData, getUserData, setAuthData, setUserData } from 'utils/auth'
import { PaymentMethod } from "constants/paymentConstants";
import mapCreditCards from 'selectors/mapCreditCards';
import mapQRs from 'selectors/mapQRs';
import mapWallet from 'selectors/mapWallet';
import mapMiiiiersWallet from 'selectors/mapMiiiiersWallet';
import mapInstallmentsPlans from 'selectors/mapInstallmentsPlans';
import fakePaymentMethodsJson from 'fakedata/fakePaymentMethods';
import fakeClientPaymentMethodsJson from 'fakedata/fakeClientPaymentMethods';
import { updateCompletedOrder } from 'store/order/actions';
import { getCardType, parseCardUrl } from "utils/cardHelper";
import { fakeValidDiscountList } from 'fakedata/fakeDiscount';
// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "PAYMENT", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

export const {
  // saveOrder,
  setCombinedPaymentAmount,
  updatePaymentMethods,
  updatePaymentMethodAmount,
  resetPaymentMethods,
  clearPayment,
  payWithNewCard,
  payWithExistingCard,
  payWithQr,
  payWithWallet,
  payWithMiiiiers,
  configureAvailablePaymentMethods,
  configureClientPaymentMethods,
  fetchPayStart,
  fetchPaySuccess,
  fetchPayFail,
  fetchPayReset,
  fetchValidDiscountStart,
  fetchValidDiscountSuccess,
  fetchValidDiscountFail,
  // fetchPaymentMethodsStart,
  // fetchPaymentMethodsSuccess,
  // fetchPaymentMethodsFail
  payCombined,
  setCombinedPrimaryAmount,
  setCombinedPrimaryPaymentMethod,
  setCombinedSecondaryPaymentMethod,
  selectInstallmentsOption,
  fetchInstallmentsPlansStart,
  fetchInstallmentsPlansFail,
  fetchInstallmentsPlansSuccess,
  resetInstallmentData
} = createActions(
  {
    // SAVE_ORDER: (token, expiresIn) => ({
    //   token,
    //   expiresIn
    // }),
    SET_COMBINED_PAYMENT_AMOUNT: (combinedPaymentAmount) => ({
      combinedPaymentAmount: combinedPaymentAmount
    }),
    UPDATE_PAYMENT_METHODS: (paymentMethods) => ({
      paymentMethods
    }),
    UPDATE_PAYMENT_METHOD_AMOUNT: (paymentMethods) => ({
      paymentMethods
    }),
    RESET_PAYMENT_METHODS: () => undefined,
    CLEAR_PAYMENT: () => undefined,
    PAY_WITH_NEW_CARD: (isCreditCard, ownerName, cardNumber, cardExpirationDate, cardCVV, documentNumber) => ({
      isCreditCard,
      newCard: {
        ownerName: ownerName,
        cardNumber: cardNumber,
        cardExpirationDate: cardExpirationDate,
        cardCVV: cardCVV
      },
      documentNumber
    }),
    PAY_WITH_EXISTING_CARD: (isCreditCard, cardId, cardCVV, documentNumber) => ({
      isCreditCard,
      cardId,
      cardCVV,
      documentNumber
    }),
    PAY_WITH_QR: (qrId) => ({
      qrId
    }),
    PAY_WITH_WALLET: () => undefined,
    PAY_WITH_MIIIIERS: () => undefined,
    CONFIGURE_AVAILABLE_PAYMENT_METHODS: (availableCards, availableQRs, availableWallet, availableMiiiiersWallet) => ({
      availableCards: mapCreditCards(availableCards),
      availableQRs: mapQRs(availableQRs),
      availableWallet: mapWallet(availableWallet),
      availableMiiiiersWallet: mapMiiiiersWallet(availableMiiiiersWallet)
    }),
    CONFIGURE_CLIENT_PAYMENT_METHODS: (paymentMethods) => ({
      paymentMethods: paymentMethods
    }),
    FETCH_PAY_START: () => undefined,
    FETCH_PAY_SUCCESS: (data) => ({
      data: data
    }),
    FETCH_PAY_FAIL: (data) => ({
      payment_error_code: data.payment_error_code,
      message: data.error_messages[0]
    }),
    FETCH_PAY_RESET: () => undefined,
    FETCH_VALID_DISCOUNT_START: () => undefined,
    FETCH_VALID_DISCOUNT_SUCCESS: (data) => ({
      data: data
    }),
    FETCH_VALID_DISCOUNT_FAIL: (error) => ({
      message: error
    }),
    PAY_COMBINED: (enable) => ({
      enable
    }),
    SELECT_INSTALLMENTS_OPTION: (option) => ({
      option
    }),
    FETCH_INSTALLMENTS_PLANS_START: () => undefined,
    FETCH_INSTALLMENTS_PLANS_FAIL: (error) => ({
      message: error
    }),
    FETCH_INSTALLMENTS_PLANS_SUCCESS: (data) => ({
      data: data
    }),
    RESET_INSTALLMENT_DATA: () => undefined,
    // FETCH_PAYMENT_METHODS_START: () => undefined,
    // FETCH_PAYMENT_METHODS_SUCCESS: (data) => ({
    //   data: data
    // }),
    // FETCH_PAYMENT_METHODS_FAIL: (error) => ({
    //   message: error
    // }),
    // FETCH_ORDER_START: () => undefined,
    // FETCH_ORDER_SUCCESS: (data) => ({
    //   description: data.description,
    //   paymentMethod: data.payment_method,
    //   currency: data.currency,
    //   totalAmount: data.total_amount,
    //   paymentState: data.order_state,
    //   shop: data.shop,
    //   operationId: data.operation_id,
    //   paymentDetails: data.order_details,
    //   successUrl: data.success_url,
    //   failureUrl: data.failure_url,
    //   expirationDate: data.expiration_date
    // }),
    // FETCH_ORDER_FAIL: (error) => ({
    //   error
    // }),
  },
  actionOptions
);

export function fetchPayWithNewCard(ownerName, cardNumber, cardExpirationDateMonth, cardExpirationDateYear, cardCVV, ownerUsername, InstallmentPlanDetailId, calculatedMount, document) {
  return async (dispatch, getState, api) => {
    await dispatch(fetchPayStart());

    const auth = getState().auth;

    var documentNumber = document;


    const cardTypeInfo = getCardType(cardNumber);
    const paymentMethodDescription = cardTypeInfo.name + " ****" + cardNumber.slice(12);
    const body = {
      amount: getState().order.order.totalAmount,
      currency: getState().order.order.currency,
      cardAmount: calculatedMount,
      documentNumber: documentNumber,
      //description: getState().order.order.description,
      paymentId: getState().order.order.token,
      sourcePayments: [{
        paymentType: "CARDS",
        sourceUserName: ownerUsername,
        cardId: "",
        cardNumber: cardNumber,
        cardExpirationDateMonth: cardExpirationDateMonth,
        cardExpirationDateYear: cardExpirationDateYear,
        cardSecurityCode: cardCVV.toString(),
        cardHolderName: ownerName,
        saveCard: auth.isAuthenticated === null ? false : auth.isAuthenticated,
        amount: getState().order.order.totalAmount
      }],
      installmentPlanDetailId: InstallmentPlanDetailId
    };
    if (getState().order.isFakeOrder) {
      dispatch(updateCompletedOrder(new Date(), paymentMethodDescription, 12345));
      dispatch(fetchPaySuccess({}));
      return;
    }

    let prom = null;

    if (auth.isAuthenticated) {
      prom = api.payment.postPayment(body);
    }
    else {
      prom = api.payment.postAnnonymousPayment(body);
    }

    return prom.then((response) => {
      dispatch(updateCompletedOrder(response.data.date, paymentMethodDescription, response.data.id));
      dispatch(fetchPaySuccess(response.data));
    }).catch((error) => {
      console.log("Error:", error.response);
      const message = get(
        error,
        'response.data',
        'Ocurrio un error al intentar obtener sus medios de pago.',
      );
      dispatch(fetchPayFail(error.response.data));
    });
  };
}

export function fetchPayWithExistingCard(ownerUsername, documentNumber) {
  return async (dispatch, getState, api) => {
    await dispatch(fetchPayStart());

    const selectedCard = getState().payment.availableCards[getState().payment.selectedItemId];
    const card = parseCardUrl(selectedCard.img);
    const cardCVV = getState().payment.selectedCVV;
    const paymentMethodDescription = card.name + " ****" + selectedCard.cardNumber;

    var user = await getUserData();

    const body = {
      amount: getState().order.order.totalAmount,
      currency: getState().order.order.currency,
      cardAmount: getState().payment.selectedInstallmentsOption.total_amount,
      //description: getState().order.order.description,
      documentNumber: documentNumber,
      paymentId: getState().order.order.token,
      sourcePayments: [
        {
          paymentType: "CARDS",
          sourceUserName: ownerUsername,
          cardId: selectedCard.cardId,
          cardNumber: null,
          cardExpirationDateMonth: null,
          cardExpirationDateYear: null,
          cardSecurityCode: cardCVV.toString(),
          cardHolderName: null,
          saveCard: false,
          amount: getState().order.order.totalAmount,
        }
      ],
      installmentPlanDetailId: getState().payment.selectedInstallmentsOption.id
    };
    if (getState().order.isFakeOrder) {
      dispatch(updateCompletedOrder(new Date(), paymentMethodDescription, 12345));
      dispatch(fetchPaySuccess({}));
      return;
    }
    return api.payment.postPayment(body).then(async (response) => {
      dispatch(updateCompletedOrder(response.data.date, paymentMethodDescription, response.data.id));
      dispatch(fetchPaySuccess(response.data));
    }).catch((error) => {
      console.log("Error:", error);
      const message = get(
        error,
        'response.data',
        'Ocurrio un error al intentar obtener sus medios de pago.',
      );
      dispatch(fetchPayFail(message));
    });
  };
}

export function fetchPayWithCvu() {
  return async (dispatch, getState, api) => {
    await dispatch(fetchPayStart());

    const paymentMethodDescription = "Mi Billetera";

    var user = await getUserData();


    const body = {
      currency: getState().order.order.currency,
      amount: getState().order.order.totalAmount,
      documentNumber: user.documentNumber,
      //description: getState().order.order.description,
      paymentId: getState().order.order.token,
      sourcePayments: [
        {
          paymentType: "CVU",
          amount: getState().order.order.totalAmount,
        }
      ],
    };

    if (getState().order.isFakeOrder) {
      dispatch(updateCompletedOrder(new Date(), paymentMethodDescription, 12345));
      dispatch(fetchPaySuccess({}));
      return;
    }

    return api.payment.postPayment(body).then((response) => {
      dispatch(updateCompletedOrder(response.data.date, paymentMethodDescription, response.data.id));
      dispatch(fetchPaySuccess(response.data));
    }).catch((error) => {
      console.log("Error:", error);
      const message = get(
        error,
        'response.data',
        'Ocurrio un error al intentar obtener sus medios de pago.',
      );
      dispatch(fetchPayFail(message));
    });
  };
}

export function fetchPayWithMiiiiers() {
  return async (dispatch, getState, api) => {
    await dispatch(fetchPayStart());

    const paymentMethodDescription = "Miiiiers";
    var user = await getUserData();

    const body = {
      currency: getState().order.order.currency,
      amount: getState().order.order.totalAmount,
      documentNumber: user.documentNumber,
      //description: getState().order.order.description,
      paymentId: getState().order.order.token,
      sourcePayments: [
        {
          paymentType: "MIIII",
          amount: getState().order.order.totalAmount,
        }
      ],
    };

    if (getState().order.isFakeOrder) {
      dispatch(updateCompletedOrder(new Date(), paymentMethodDescription, 12345));
      dispatch(fetchPaySuccess({}));
      return;
    }

    return api.payment.postPayment(body).then((response) => {
      dispatch(updateCompletedOrder(response.data.date, paymentMethodDescription, response.data.id));
      dispatch(fetchPaySuccess(response.data));
    }).catch((error) => {
      console.log("Error:", error);
      const message = get(
        error,
        'response.data',
        'Ocurrio un error al intentar obtener sus medios de pago.',
      );
      dispatch(fetchPayFail(message));
    });
  };
}

export function getAvailablePaymentMethods(clientId) {
  return async (dispatch, getState, api) => {

    var paymentMethods = api.payment.getPaymentMethodsByClient(clientId)

    const creditCards = [];
    const qrs = [];
    let wallet = null;
    let miiiiers = null;
    paymentMethods.paymentMethods.forEach((element) => {
      switch (element.cardType) {
        case "CVU":
          wallet = element;
          break;

        case "MIIII":
          miiiiers = element;
          break;

        case "INGENICO":
          creditCards.push(element);
          break;

        case "CBU":
          qrs.push(element);
          break;

        default:
          break;
      }
    });

    dispatch(configureAvailablePaymentMethods(creditCards, qrs, wallet, miiiiers));
  };
}

export function fakeConfigureAvailablePaymentMethods() {
  return async (dispatch, getState, api) => {
    const data = fakePaymentMethodsJson;

    const creditCards = [];
    const qrs = [];
    let wallet = null;
    let miiiiers = null;
    data.cards.forEach((element) => {
      switch (element.cardType) {
        case "CVU":
          wallet = element;
          break;

        case "MIIII":
          miiiiers = element;
          break;

        case "INGENICO":
          creditCards.push(element);
          break;

        case "CBU":
          qrs.push(element);
          break;

        default:
          break;
      }
    });

    dispatch(configureAvailablePaymentMethods(creditCards, qrs, wallet, miiiiers));

    dispatch(configureClientPaymentMethods(fakeClientPaymentMethodsJson.data));
  };
}
export function fetchInstallmentsPlans(data) {
  return async (dispatch, getState, api) => {
    await dispatch(fetchInstallmentsPlansStart());

    const cartTypeId = data.cardTypeId;
    const cardId = data.cardId;
    const cardNumber = data.cardNumber.slice(0, 6);
    const order = getState().order.order;

    let amount = 0;

    if (getState().payment.combinedPayment) {
      var cardMethod = getState().payment.selectedPaymentMethods.filter(x => x.method === PaymentMethod.DEBIT_CARD || x.method === PaymentMethod.CREDIT_CARD);
      if (cardMethod.length > 0 && cardMethod[0].amount > 0)
        amount = cardMethod[0].amount;
      else {
        var combinedAmount = getState().payment.selectedPaymentMethods.reduce(function (sum, item) { return sum + parseFloat(item.amount) }, 0);

        amount = getState().order.order.totalAmount - combinedAmount;

        setCombinedPaymentAmount(amount)
        var paymentMethods = getState().payment.selectedPaymentMethods.slice();

        let updatedCardAmount = paymentMethods.map((paymentMethod) => {
          if (paymentMethod.method === PaymentMethod.DEBIT_CARD || paymentMethod.method === PaymentMethod.CREDIT_CARD)
            paymentMethod.amount = amount;

          return paymentMethod;
        })

        dispatch(updatePaymentMethods(updatedCardAmount));
      }
    } else
      amount = getState().order.order.totalAmount;

    if (cardId !== null && cardId !== undefined) {
      return api.payment.getInstallments(cardNumber, amount, cartTypeId, cardId, order.token).then(async (response) => {
        dispatch(fetchInstallmentsPlansSuccess(mapInstallmentsPlans(response.data)));
      }).catch((error, response) => {
        console.log("Error:", error);
        const message = get(
          error,
          'response.data.message',
          'Ocurrio un error al intentar obtener el plan de cuotas.',
        );
        dispatch(fetchInstallmentsPlansFail(error.response.data.error_messages[0]));
      });
    }
    else {
      return api.payment.getAnnonInstallments(cardNumber, amount, order.token).then(async (response) => {
        dispatch(fetchInstallmentsPlansSuccess(mapInstallmentsPlans(response.data)));
      }).catch((error) => {
        console.log("Error:", error);
        const message = get(
          error,
          'response.data.message',
          'Ocurrio un error al intentar obtener el plan de cuotas.',
        );
        dispatch(fetchInstallmentsPlansFail(error.response.data.error_messages[0]));
      });
    }
  };
}

export function updateCombinedPaymentMethods(method) {
  return async (dispatch, getState) => {
    let paymentMethods = getState().payment.selectedPaymentMethods.slice();

    const index = paymentMethods.findIndex(x => x.method === method);

    if (index > -1) {
      paymentMethods = paymentMethods.filter(x => x.method !== method);
    } else {
      paymentMethods.push(
        {
          method: method,
          amount: 0
        });
    }
    dispatch(updatePaymentMethods(paymentMethods));
  };
}

export function updateCombinedPaymentMethodAmount(method, amount) {
  return async (dispatch, getState) => {
    let paymentMethods = getState().payment.selectedPaymentMethods.slice();

    const index = paymentMethods.findIndex(x => x.method === method);

    if (index > -1)
      paymentMethods[index].amount = amount;

    dispatch(updatePaymentMethods(paymentMethods));
  };
}

export function payCombinedPayment(isNewCard, ownerUsername, ownerName, cardNumber, cardExpirationDateMonth, cardExpirationDateYear, cardCVV, InstallmentPlanDetailId, calculatedMount) {
  return async (dispatch, getState, api) => {

    await dispatch(fetchPayStart());

    const paymentMethodDescription = "Pago combinado";

    var paymentMethods = getState().payment.selectedPaymentMethods;
    var documentNumber = getState().payment.documentNumber;

    var user = await getUserData();

    let paymentList = [];

    paymentMethods.map(async (paymentMethod) => {
      switch (paymentMethod.method) {
        case PaymentMethod.DEBIT_CARD:
        case PaymentMethod.CREDIT_CARD:
          if (isNewCard) {
            let payment = await dispatch(getPaymentForNewCreditCard(ownerName, cardNumber, cardExpirationDateMonth, cardExpirationDateYear, cardCVV, ownerUsername, InstallmentPlanDetailId, paymentMethod.amount));
            paymentList.push(payment);
          }
          else {
            let payment = await dispatch(getPaymentForExistingCreditCard(ownerUsername));
            paymentList.push(payment);
          }
          break;

        case PaymentMethod.WALLET:
          paymentList.push({
            paymentType: "CVU",
            amount: paymentMethod.amount
          })
          break;
        case PaymentMethod.MIIIIERS:
          paymentList.push({
            paymentType: "MIIII",
            amount: paymentMethod.amount
          })
          break;
        case PaymentMethod.QR:
          paymentList.push({
            paymentType: "",
            amount: paymentMethod.amount
          })
          break;

        default:
          break;
      }
    })

    const body = {
      currency: getState().order.order.currency,
      amount: getState().order.order.totalAmount,
      cardAmount: getState().payment.selectedInstallmentsOption?.total_amount,
      documentNumber: documentNumber ? documentNumber : user.documentNumber,
      //description: getState().order.order.description,
      paymentId: getState().order.order.token,
      sourcePayments: paymentList,
      installmentPlanDetailId: InstallmentPlanDetailId != null ? InstallmentPlanDetailId : getState().payment.selectedInstallmentsOption?.id
    };

    if (getState().order.isFakeOrder) {
      dispatch(updateCompletedOrder(new Date(), paymentMethodDescription, 12345));
      dispatch(fetchPaySuccess({}));
      return;
    }

    return api.payment.postPayment(body).then((response) => {
      dispatch(updateCompletedOrder(response.data.date, paymentMethodDescription, response.data.id));
      dispatch(fetchPaySuccess(response.data));
    }).catch((error) => {
      console.log("Error:", error);
      const message = get(
        error,
        'response.data.message',
        'Ocurrio un error al intentar obtener sus medios de pago.',
      );
      dispatch(fetchPayFail(message));
    });

  };
}

function getPaymentForExistingCreditCard(ownerUsername) {
  return async (dispatch, getState) => {
    const selectedCard = getState().payment.availableCards[getState().payment.selectedItemId];
    const card = parseCardUrl(selectedCard.img);
    const cardCVV = getState().payment.selectedCVV;

    let amount = getState().payment.selectedPaymentMethods.filter(x => x.method === PaymentMethod.DEBIT_CARD || x.method === PaymentMethod.CREDIT_CARD)[0].amount;

    let payment = {
      paymentType: "TC",
      amount: amount,
      sourceUserName: ownerUsername,
      cardId: selectedCard.cardId,
      cardNumber: null,
      cardExpirationDateMonth: null,
      cardExpirationDateYear: null,
      cardSecurityCode: cardCVV.toString(),
      cardHolderName: null,
      saveCard: false,
    }

    return payment;
  }
}

function getPaymentForNewCreditCard(ownerName, cardNumber, cardExpirationDateMonth, cardExpirationDateYear, cardCVV, ownerUsername, InstallmentPlanDetailId, amount) {
  return async (dispatch, getState) => {

    const auth = getState().auth;

    const cardTypeInfo = getCardType(cardNumber);

    let payment = {
      paymentType: "TC",
      amount: amount,
      sourceUserName: ownerUsername,
      cardId: "",
      cardNumber: cardNumber,
      cardExpirationDateMonth: cardExpirationDateMonth,
      cardExpirationDateYear: cardExpirationDateYear,
      cardSecurityCode: cardCVV.toString(),
      cardHolderName: ownerName,
      saveCard: auth.isAuthenticated === null ? false : auth.isAuthenticated,
    }

    return payment;
  }
}

export function resetCombinedPaymentAmount() {
  return async (dispatch, getState) => {
    let paymentMethods = getState().payment.selectedPaymentMethods.slice();

    let resetedAmountArray = paymentMethods.map((paymentMethod) => {
      paymentMethod.amount = 0;
      return paymentMethod;
    }
    )
    dispatch(updatePaymentMethods(resetedAmountArray));
  };
}


//  export async function fakeFetchValidDiscount(discountCode) {
//  {

//       console.log("Codigo",discountCode);
//       console.log("fakeValid",fakeValidDiscount);
//       if(discountCode === fakeValidDiscount.code)
//         return fakeValidDiscount;
//       else
//         return fakeInvalidDiscount;

// }

// export function fetchPaymentMethods(email, password) {
//   return async (dispatch, getState, api) => {
//       await dispatch(fetchPaymentMethodsStart());

//       return api.payment.getPaymentMethods().then(async (response) => {
//           const creditCards = [];
//           const qrs = [];
//           let wallet = null;
//           let miiiiers = null;
//           response.data.cards.forEach((element) => {
//             switch (element.cardType) {
//               case "CVU":
//                 wallet = element;
//                 break;

//               case "MIIII":
//                 miiiiers = element;
//                 break;

//               case "INGENICO":
//                 creditCards.push(element);
//                 break;

//               case "CBU":
//                 qrs.push(element);
//                 break;

//               default:
//                 break;
//             }
//           });
//           dispatch(configureAvailablePaymentMethods(creditCards, qrs, wallet, miiiiers));
//           dispatch(fetchPaymentMethodsSuccess());
//       }).catch((error) => {
//           console.log("Error:",error);
//           const message = get(
//               error,
//               'response.data.message',
//               'Ocurrio un error al intentar obtener sus medios de pago.',
//           );
//           dispatch(fetchPaymentMethodsFail(message));
//       });
//   };
// }


export function fetchValidDiscount(
  discountCode,
) {

  //   return async (dispatch, getState, api) => {
  //     await dispatch(fetchValidDiscountStart());
  //     //return api.payment.discount(discountCode).then(async (response) => {
  //     return fakeFetchValidDiscount(discountCode).then(async (response) => {
  //       console.log("laresponse",response.data);
  //         // Se retorna el valor de la api y se manda al metodo de success.

  //         dispatch(fetchValidDiscountSuccess(response.data));
  //     }).catch((error) => {
  //         console.log("Error:",error);
  //         const message = get(
  //             error,
  //             'response.data.message',
  //             'Ocurrio un error al intentar validar el descuento.',
  //         );
  //         dispatch(fetchValidDiscountFail(message));
  //     });
  // };

  return async (dispatch, getState, api) => {
    await dispatch(fetchValidDiscountStart());
    if (discountCode === fakeValidDiscountList[0].code) {
      await dispatch(fetchValidDiscountSuccess(fakeValidDiscountList));
      return;
    } else {
      await dispatch(fetchValidDiscountFail("¡Ups! El código ingresado no es valido"));
      return;
    }

  };
}
