import { paymentAnonInstance, paymentInstance } from "./api.js";

export default {
  getOrder: (token) => paymentAnonInstance.get('api/paymentgateway/payment/'+token),
  getPaymentMethods: () => paymentInstance.get('api/paymentgateway/client/get-payment-options'),
  postAnnonymousPayment: (body) => paymentAnonInstance.post('api/paymentgateway/payment/pay', body),
  postPayment: (body) => paymentInstance.post('api/paymentgateway/payment/user/pay', body),
  getAnnonInstallments: (cardNumber,amount, paymentId) => paymentAnonInstance.get('api/paymentgateway/card_installments'+'?cardNumber='+ cardNumber + '&amount='+ amount + '&paymentId='+ paymentId),
  getInstallments: (cardNumber,amount,cardTypeId, cardId, paymentId) => 
    paymentInstance.get('api/paymentgateway/card_installments'+'?cardNumber='+ cardNumber + '&amount='+ amount +'&cardTypeId=' + cardTypeId + '&cardCode=' + cardId + '&paymentId='+ paymentId),
  getPaymentMethodsByClient: (clientId) => paymentInstance.get('api/paymentgateway/client/' + clientId + '/payment-methods') 
};
