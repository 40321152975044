export default {
  "description": "Donacion de prueba",
  "payment_method": "Tarjeta de Débito",
  "currency": "ARS",
  "amount": 3000.0,
  "payment_state": "NEW",
  "client": "Test Client",
  "operation_id": "7d3034f7-f0df-47b2-8d62-652487eaa4a8",
  "payment_details": "[{\"description\": \"Item 1\",\"amount\": 1000.0,\"quantity\": 1},{\"description\": \"Item 2\",\"amount\": 1000.0,\"quantity\": 1},{\"description\": \"Item 3\",\"amount\": 1000.0,\"quantity\": 1}]",
  "payment_details_items": [
    {
      "description": "Item 1",
      "amount": 1000.0,
      "quantity": 1
    },
    {
      "description": "Item 2",
      "amount": 1000.0,
      "quantity": 1
    },
    {
      "description": "Item 3",
      "amount": 1000.0,
      "quantity": 1
    }
  ],
  "success_url": "https://www.google.com",
  "failure_url": "https://www.google.com",
  "expiration_date": "2020-07-18T22:05:15.510863",
  "creation_date": "2020-07-16T18:55:15.511763",
  "token": "e55dd589ce604fe69828119c01c14e75",
  "client_description": "Fundación Cuentos que Alimentan",
  "update_date": null,
  "paymentMethodDescription": null,
  "transaction_id": 1234,
  "logo_url": "http://www.artevivo.org.ar/images/logo-rojo.gif"
}