import { createSelector } from "reselect";

const mapWallet = createSelector(
  wallet => wallet,
  wallet =>
    typeof wallet !== "undefined" &&
    wallet !== null
      ? {
          bankAccountId: wallet.bankAccountId,
          cbu: wallet.cbu,
          alias: wallet.alias,
          balance: wallet.balance,
          balanceIntPart: wallet.cardAmount.intPart,
          balanceDecimalPart: wallet.cardAmount.decimalPart,
          holderName: wallet.cardDetail.holderName,
          holderCuit: wallet.cardDetail.holderCuit,
          bankName: wallet.cardDetail.bankName,
          footerLeft: wallet.cardFooterText.left,
          footerRight: wallet.cardFooterText.right,
          color: wallet.cardMiiiiLogoColor,
          img: wallet.cardImageUrl
      }
      : null
);

export default mapWallet;
