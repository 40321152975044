import { createActions } from "redux-actions";

// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "TOAST",
  namespace: "-"
};

export const { 
    showToast,
    hideToast
  } = createActions(
    {
      SHOW_TOAST: (message,type) => ({
        toastMessage: message,
        toastType: type
      }),
      HIDE_TOAST: () => undefined,
    },
    actionOptions
  );
