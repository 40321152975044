export default {
  "isFake": true,
  "username": "7460f7cb-9bc5-4be0-b4e5-c7ad3e92db2d",
  "gender": 0,
  "profile_picture": "https://s3.us-east-2.amazonaws.com/miiiisa-app/masterdata/usrimg/637279016172747102-dmICqX.png",
  "neighbourhoodpropertiesregistrationcode": null,
  "personid": 5232,
  "name": "Mario",
  "family_name": "Zeta",
  "state": 2,
  "email": "mario.zelarayan@codetria.com",
  "phone_number": "+1125997494",
  "dni": "37350559",
  "birthdate": null,
  "isunderage": false
}