export default {
  "balance": 0.0,
  "picture_url": "https://s3.us-east-2.amazonaws.com/miiiisa-app-prod/masterdata/usrimg/637008844235109761-kVvOnk.png",
  "hascvurequestpending": false,
  "itauaccountactivationurl": "",
  "itauaccountactivation_button_sysparam": "",
  "showitauaccountactivation": false,
  "showitaudebitsubscription": true,
  "forceitaudebitsubscription": true,
  "forceitauonboardinginvitados": false,
  "isowner": true,
  "isfamiliar": true,
  "isemployee": false,
  "cvu": "0000010900000000000123",
  "alias": "fdesena.mi",
  "cards": [
      {
          "bankAccountId": 9,
          "cbu": "0000010900000000000123",
          "alias": "fdesena.mi",
          "balance": 2001.78,
          "cardType": "CVU",
          "cardDetail": {
              "cardId": "",
              "holderName": "Fernando De Sena",
              "holderCuit": "20320470189",
              "bankName": "BANCO ITAU ARGENTINA S.A.",
              "cardNumber": "",
              "cardExpirationDate": "",
              "cardType": null
          },
          "cardAmount": {
              "intPart": "$1.001",
              "decimalPart": "78"
          },
          "cardFooterText": {
              "left": "Disponible",
              "right": "CUENTA VIRTUAL"
          },
          "cardMiiiiLogoColor": "0x8A000000",
          "cardImageUrl": "https://miiiisa-landing-web.s3.us-east-2.amazonaws.com/img/appmobile/Itau-CA-CVU.png"
      },
      {
          "bankAccountId": 1100,
          "cbu": "",
          "alias": "",
          "balance": 4000.44,
          "cardType": "MIIII",
          "cardDetail": {
              "cardId": "",
              "holderName": "Fernando De Sena",
              "holderCuit": "20320470189",
              "bankName": "",
              "cardNumber": "",
              "cardExpirationDate": "",
              "cardType": null
          },
          "cardAmount": {
              "intPart": "200",
              "decimalPart": ""
          },
          "cardFooterText": {
              "left": "Disponible",
              "right": "MIIIIERS"
          },
          "cardMiiiiLogoColor": "0x8A000000",
          "cardImageUrl": "https://miiiisa-app.s3.us-east-2.amazonaws.com/wallet/MiiiiersCard.png"
      },
      {
          "bankAccountId": null,
          "cbu": "",
          "alias": "",
          "balance": 0.0,
          "cardType": "INGENICO",
          "cardDetail": {
              "cardId": "sVY9teY4d6kZ8rzJyLZz30ZHJsGqdrnf",
              "holderName": "P DE SENA FERNANDO",
              "holderCuit": "20320470189",
              "bankName": "",
              "cardNumber": "0527",
              "cardExpirationDate": "2311",
              "cardType": "Unknown"
          },
          "cardAmount": null,
          "cardFooterText": {
              "left": "",
              "right": "11/23"
          },
          "cardMiiiiLogoColor": "0x8A000000",
          "cardImageUrl": "https://miiiisa-landing-web.s3.us-east-2.amazonaws.com/img/appmobile/Itau-CA-CVU.png"
      },
      {
          "bankAccountId": null,
          "cbu": "",
          "alias": "",
          "balance": 0.0,
          "cardType": "INGENICO",
          "cardDetail": {
              "cardId": "TNxNfzia39GFUueDbQE8DuFCoP1Qas2A",
              "holderName": "DE SENA FERNANDO",
              "holderCuit": "20320470189",
              "bankName": "",
              "cardNumber": "9963",
              "cardExpirationDate": "2502",
              "cardType": "Unknown"
          },
          "cardAmount": null,
          "cardFooterText": {
              "left": "",
              "right": "02/25"
          },
          "cardMiiiiLogoColor": "0x8A000000",
          "cardImageUrl": "https://miiiisa-landing-web.s3.us-east-2.amazonaws.com/img/appmobile/Itau-CA-CVU.png"
      },
      {
          "bankAccountId": null,
          "cbu": "",
          "alias": "",
          "balance": 0.0,
          "cardType": "INGENICO",
          "cardDetail": {
              "cardId": "g2WIhz2NgT0yD1FT8soMncPTAPtuC38B",
              "holderName": "FERNANDO PA DE SENA",
              "holderCuit": "20320470189",
              "bankName": "",
              "cardNumber": "0960",
              "cardExpirationDate": "2311",
              "cardType": "Unknown"
          },
          "cardAmount": null,
          "cardFooterText": {
              "left": "",
              "right": "11/23"
          },
          "cardMiiiiLogoColor": "0x8A000000",
          "cardImageUrl": "https://miiiisa-landing-web.s3.us-east-2.amazonaws.com/img/appmobile/Itau-CA-CVU.png"
      },
      {
          "bankAccountId": null,
          "cbu": "2590020220356246930134",
          "alias": "",
          "balance": 0.0,
          "cardType": "CBU",
          "cardDetail": {
              "cardId": "",
              "holderName": "Fernando De Sena",
              "holderCuit": "20320470189",
              "bankName": "BANCO ITAU ARGENTINA S.A.",
              "cardNumber": "",
              "cardExpirationDate": "",
              "cardType": null
          },
          "cardAmount": null,
          "cardFooterText": {
              "left": "Copiar CBU",
              "right": "CAJA AHORRO"
          },
          "cardMiiiiLogoColor": "0x8AFFFFFF",
          "cardImageUrl": "https://miiiisa-landing-web.s3.us-east-2.amazonaws.com/img/appmobile/Itau-CA-CBU.png"
      },
      {
          "bankAccountId": null,
          "cbu": "2590029520355392430118",
          "alias": "",
          "balance": 0.0,
          "cardType": "CBU",
          "cardDetail": {
              "cardId": "",
              "holderName": "Fernando De Sena",
              "holderCuit": "20320470189",
              "bankName": "BANCO ITAU ARGENTINA S.A.",
              "cardNumber": "",
              "cardExpirationDate": "",
              "cardType": null
          },
          "cardAmount": null,
          "cardFooterText": {
              "left": "Copiar CBU",
              "right": "CAJA AHORRO"
          },
          "cardMiiiiLogoColor": "0x8AFFFFFF",
          "cardImageUrl": "https://miiiisa-landing-web.s3.us-east-2.amazonaws.com/img/appmobile/Itau-CA-CBU.png"
      }
  ],
  "cuit": 20320470189,
  "userName": "fer",
  "fullName": "Fernando De Sena",
  "email": null,
  "phoneNumber": "+5491162954538",
  "birthdate": null,
  "id": 12
}