import { get } from 'lodash-es';
import { createActions } from "redux-actions";
import { getAuthData, getUserData, setAuthData, setUserData } from 'utils/auth'
import { fakeConfigureAvailablePaymentMethods, 
        configureAvailablePaymentMethods,
        configureClientPaymentMethods } from "store/payment/actions";
import fakeUserJson from 'fakedata/fakeUser';
import { normalizeError } from 'utils/requestErrorNormalizer';
import apiConstants from "constants/apiConstants.js";

import {
  showWalletTab,
  showCardTab,
  showMiiiiersTab,
  showQRTab
} from "store/navigation/actions";


// ------------------- //
// ACTIONS SINCRÓNICOS //
// ------------------- //

const actionOptions = {
  prefix: "AUTH", // Prefijo usado para los actions creados
  namespace: "-" // Separador usado entre el prefijo y el nombre del action
  // No usar "_" como separador, o se rompe el naming de las variables
};

export const { 
  authenticate,
  unauthenticate,
  saveUser,
  clearUser,
  loginStart,
  loginSuccess,
  loginFail
} = createActions(
  {
    AUTHENTICATE: (token, expiresIn) => ({
      token,
      expiresIn
    }),
    UNAUTHENTICATE: () => undefined,
    SAVE_USER: (userInfo) => ({
      username: userInfo.username,
      gender: userInfo.gender,
      profile_picture: userInfo.profile_picture,
      neighbourhoodpropertiesregistrationcode: userInfo.neighbourhoodpropertiesregistrationcode,
      personid: userInfo.personid,
      name: userInfo.name,
      family_name: userInfo.family_name,
      state: userInfo.state,
      email: userInfo.email,
      phone_number: userInfo.phone_number,
      dni: userInfo.dni,
      birthdate: userInfo.birthdate,
      isunderage: userInfo.isunderage,
      donationAmount: userInfo.donationAmount
    }),
    CLEAR_USER: () => undefined,
    LOGIN_START: () => undefined,
    LOGIN_SUCCESS: (data) => ({
      data: data
    }),
    LOGIN_FAIL: (error) => ({
      message: error
    }),
  },
  actionOptions
);

// -------------------- //
// ACTIONS ASINCRÓNICOS //
// -------------------- //

// Dispatch es la funcion dispatcher que ejecutará la acción generada
// getState es un método de React-Redux, devuelve el estado del store antes del dispatch
// api es un parametro extra enviado desde el middleware del store, usando thunk.withExtraArgument()
export function logIn(email, password) {
    return async (dispatch, getState, api) => {
        await dispatch(loginStart());

        // Fake login
        if (apiConstants.enableFakeData && email === "admin" && password === "123456"){
          await dispatch(fakeLogIn());
          return;
        }

        return api.auth.login(email, password).then(async (response) => {
            await setAuthData(true, response.data.auth.access_token, response.data.auth.expiration_date);
            await setUserData(response.data.user.name, response.data.user.username, response.data.user.dni);

            dispatch(authenticate(response.data.auth.access_token, response.data.auth.expiration_date));
            dispatch(saveUser(response.data.user));
            dispatch(loginSuccess());
        }).catch((error) => {
            console.log("Error:", error);
            const message = get(error, "response.data.message", 'Ocurrio un error al intentar iniciar sesión.');
            dispatch(loginFail(normalizeError(message, 'Ocurrio un error al intentar iniciar sesión.')));
        });
    };
}

export function logInAndFetchPaymentMethods(email, password) {
  return async (dispatch, getState, api) => {
    await dispatch(loginStart());

    // Fake login
    if (apiConstants.enableFakeData && email === "admin" && password === "123456"){
      await dispatch(fakeLogIn());
      return;
    }

    return api.auth.login(email, password).then(async (response) => {
      await setAuthData(true, response.data.auth.access_token, response.data.auth.expiration_date);
      await setUserData(response.data.user.name, response.data.user.username, response.data.user.dni);

      return api.payment.getPaymentMethods().then(async (response2) => {
        const creditCards = [];
        const qrs = [];
        let wallet = null;
        let miiiiers = null;
        if (response2.data.cards != null){
          response2.data.cards.forEach((element) => {
            switch (element.cardType) {
              case "CVU":
                wallet = element;
                break;
  
              case "MIIII":
                miiiiers = element;
                break;
  
              case "INGENICO":
              case "TC":
                creditCards.push(element);
                break;
  
              case "CBU":
                qrs.push(element);
                break;
            
              default:
                break;
            }
          });
        }        

        var clientId = getState().order.order.clientId;

        var clientMethodsResponse = await api.payment.getPaymentMethodsByClient(clientId);

        switch(clientMethodsResponse.data.filter(x => x.code !== "CASH" && x.code !== "CBU")[0].code){
          case "CVU":
            dispatch(showWalletTab());
            break;

          case "MIIII":
            dispatch(showMiiiiersTab());
            break;

          case "INGENICO":
          case "TC":
          case "TD":
            dispatch(showCardTab());
            break;

          case "QR":
            dispatch(showQRTab());
            break;
        
          default:
            dispatch(showWalletTab());
            break;
        }

        dispatch(authenticate(response.data.auth.access_token, response.data.auth.expiration_date));
        dispatch(saveUser(response.data.user));
        dispatch(configureAvailablePaymentMethods(creditCards, qrs, wallet, miiiiers));
        dispatch(configureClientPaymentMethods(clientMethodsResponse.data));
        dispatch(loginSuccess());   
      }).catch((error) => {
        console.log("Error:", error);
        const message = get(error, "response.data.message", 'Ocurrio un error al intentar iniciar sesión.');
        dispatch(loginFail(normalizeError(message, 'Ocurrio un error al intentar iniciar sesión.')));
      }); 
    }).catch((error) => {
      console.log("Error:", error);
      const message = get(error, "response.data.message", 'Ocurrio un error al intentar iniciar sesión.');
      dispatch(loginFail(normalizeError(message, 'Ocurrio un error al intentar iniciar sesión.')));
    });
  };
}

export function fakeLogIn() {
  return async (dispatch, getState, api) => {
      dispatch(loginStart());

      const sleep = m => new Promise(r => setTimeout(r, m))
      await sleep(5000);

      const fakeToken = "FAKETOKEN";
      const fakeExpires = 999999999;
      const fakeUser = fakeUserJson;
      await setAuthData(true, fakeToken, fakeExpires);
      await setUserData(fakeUser);

      dispatch(fakeConfigureAvailablePaymentMethods());
      dispatch(authenticate(fakeToken, fakeExpires));
      dispatch(saveUser(fakeUser));
      dispatch(loginSuccess());
      return;
  };
}

export function logOut() {
    return async (dispatch, getState, api) => {
        await setAuthData(false, '', '');
        dispatch(unauthenticate());
        dispatch(clearUser());
    };
}

// export function checkAuthentication() {
//     return async (dispatch, getState, api) => {
//         const auth = await getAuthData();
//         const user = await getUserData();
//         if (user == null || auth == null){
//           dispatch(unauthenticate())
//           dispatch(clearUser())
//           return;
//         }

//         const isAuthenticated = (typeof auth.authenticated === "string" && auth.authenticated === 'true');
//         const token = auth.token;
//         const expiresIn = auth.expiresIn;

//         if (isAuthenticated)
//         {
//             dispatch(authenticate(token, expiresIn))
//             dispatch(saveUser(user))
//         }
//         else{
//             dispatch(unauthenticate())
//             dispatch(clearUser())
//         }
//     };
// }