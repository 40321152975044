export default {
  data : [
    {
        "id": "5", 
        "description" : "CBU", 
        "code": "CBU"
    },
    {
        "id":"3", 
        "description": "CVU",
        "code": "CVU"
    },
    {
        "id":"2", 
        "description": "Tarjeta de Crédito", 
        "code": "TC"},
    {
        "id":"2", 
        "description": "Miiiers", 
        "code": "MIIII"
    }
  ]
}