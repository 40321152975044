import axios from "axios";
import payment from "./payment";
import auth from "./auth";
import apiConstants from "constants/apiConstants"

import { getAuthToken, setAuthToken } from "utils/auth.js";

// export const API_URL = `${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PATH}`;

const createAnonInstance = (baseUrl) => {
  const instance = axios.create({baseURL: baseUrl});
  instance.interceptors.response.use(response => {
    return response;
  });

  instance.interceptors.request.use(async config => {
    return config;
  });

  return instance;
}

const createInstance = (baseUrl) => {
  const instance = axios.create({baseURL: baseUrl});
  instance.interceptors.response.use(response => {
    return response;
  });

  instance.interceptors.request.use(async config => {
    const token = getAuthToken();
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["x-access-token"] = `${token}`;
    return config;
  });

  return instance;
}

export const paymentAnonInstance = createAnonInstance(apiConstants.payment);
export const paymentInstance = createInstance(apiConstants.payment);
export const authenticationAnonInstance = createAnonInstance(apiConstants.authentication);
export const authenticationInstance = createInstance(apiConstants.authentication);

// ----------------- //
// Instancia anónima //
// ----------------- //

// axiosAnonInstance.interceptors.response.use(response => {
//   return response;
// });

// axiosAnonInstance.interceptors.request.use(async config => {
//   return config;
// });

// ----------------- //
// Instancia con token //
// ----------------- //

// export const axiosInstance = axios.create({
//   baseURL: API_URL
// });

// 

export default {
  payment,
  auth
};
